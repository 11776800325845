<template>
  <!-- <div> 
    <button
      type="button"
      class="btn btn-success"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      style="float:right"
      @click="maj('','ajouter')"
    >
      Ajouter
    </button>
  </div> -->
  <loader v-if="showloader == true"></loader>
  <div class="card">
  <div class="card-header">
    <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-250px ps-15"
            placeholder="Recherche"
            @change="searchdata"
            v-model="searchValue"
          />
        </div>
    </div>
    <div class="card-toolbar">
        <a class="btn btn-primary" :href="url+'/membre/export'" type="button" target="_blank">
            <span class="svg-icon svg-icon-2">
                <img src="media/icons/duotune/files/fil009.svg">
            </span>Export Excel
        </a>
    </div>
  </div>
</div>
  <div class="card">
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200"
              >
                <th>Nom</th>
                <th>Prénom</th>
                <th>Email</th>
                <th>Tél</th>
                <th>Offre</th>                
                <th>Nb Module</th>
                <th>Nb Sequence</th>
                <th>Nb Question</th>
                <th class="min-w-120px">Progress Formation</th>
                <th class="min-w-120px">Progress Test</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.firstname }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phone }}</td>
                <td>
                  <div v-for="(offre, index) in item.ListeOffre" :key="index">
                    <p class="text-dark fw-bolder text-hover-primary fs-8">
                      <span v-if="offre.mode == 0" style="color: red"
                        >Gratuit</span
                      >
                      <span v-if="offre.mode == 1" style="color: green"
                        >Payant</span
                      >
                    </p>
                  </div>
                </td>
                <td>{{ item.NbModule }}</td>
                <td>{{ item.NbSequence }}</td>
                <td> {{ item.NbTrueQuestion }} /{{ item.NbQuestion }}</td>
                <td>
                  <div class="d-flex flex-column w-100 me-2">
                    <div class="d-flex flex-stack mb-2">
                      <span class="text-muted me-2 fs-7 fw-bold">
                        {{ Math.round(item.pourcentageF) }}%
                      </span>
                    </div>

                    <div class="progress h-6px w-100">
                      <div
                        class="progress-bar"
                        :class="`bg-${item.color}`"
                        role="progressbar"
                        :style="{ width: Math.round(item.pourcentageF) + '%' }"
                        :aria-valuenow="Math.round(item.pourcentageF)"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </td>
                <td class="text-end">
                  <div class="d-flex flex-column w-100 me-2">
                    <div class="d-flex flex-stack mb-2">
                      <span class="text-muted me-2 fs-7 fw-bold">
                        {{ Math.round(item.pourcentageT) }}%
                      </span>
                    </div>

                    <div class="progress h-6px w-100">
                      <div
                        class="progress-bar"
                        :class="`bg-${item.color}`"
                        role="progressbar"
                        :style="{ width: Math.round(item.pourcentageT) + '%' }"
                        :aria-valuenow="Math.round(item.pourcentageT)"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <Toggle
                    class="toggle-blue"
                    v-model="item.isActive"
                    @click="IsActiveUser(item)"
                  />
                </td>
                <!-- <td>
            <input type="checkbox" @click="IsActiveUser(item)" true-value=1 false-value=0 v-model="item.isActive"> -->
                <!-- <div class="d-flex justify-content-start">
                  <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input class="form-check-input" name="google" type="checkbox" @click="IsActiveUser(item)" true-value=0 false-value=1 v-model="item.isActive" id="kt_modal_connected_accounts_google">
                  <span class="form-check-label fw-bold text-muted" for="kt_modal_connected_accounts_google"></span>
                  </label>
                </div> -->
                <!-- <label class="switch">
                    <input type="checkbox" @click="IsActiveUser(item)" true-value=1 false-value=0 v-model="item.isActive">
                    <span class="slider round"></span>
                </label> -->
                <!-- <div class="d-flex justify-content-end">
                
                <label
                  class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                >
                 
                  <input
                    class="form-check-input"
                    name="google"
                    type="checkbox"
                    @click="IsActiveUser(item)" true-value=1 false-value=0 v-model="item.isActive"
                    id="kt_modal_connected_accounts_google"
                    
                  />
                 
                  <span
                    class="form-check-label fw-bold text-muted"
                    for="kt_modal_connected_accounts_google"
                  ></span>
                  
                </label>
                
              </div> -->
                <!-- </td>
            <td>
                <span class="action_icon"><i class="fa fa-edit fa-2x" @click="maj(item,'editer')" style="cursor:pointer"  data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"></i>Edit</span>
            </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" id="kt_modal_1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ formMode }} Membre</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>
        <!--Start Body modal add user-->
        <div class="modal-body">
          <form class="row g-3">
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="name" class="form-label required">Nom </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
              <div class="col-md-6">
                <label for="firstname" class="form-label required"
                  >Prénom
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  class="form-control m-2"
                  v-model="add_data.firstname"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label
                  for="email"
                  class="form-label required"
                  v-if="formMode == 'ajouter'"
                  >Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  class="form-control m-2"
                  v-model="add_data.email"
                  v-if="formMode == 'ajouter'"
                />
              </div>
              <div class="col-md-6">
                <label for="phone" class="form-label">Téléphone :</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  class="form-control m-2"
                  v-model="add_data.phone"
                />
              </div>
            </div>
            <div class="row" v-if="formMode == 'ajouter'">
              <div class="col-md-6 padding_left_none">
                <label for="inputEmail4" class="form-label required"
                  >créer un mot de passe
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  class="form-control m-2"
                  v-model="add_data.password"
                />
              </div>
              <div class="col-md-6">
                <label for="password_confirmation" class="form-label required"
                  >confirmation du mot de passe
                </label>
                <input
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  class="form-control m-2"
                  v-model="add_data.password_confirmation"
                />
              </div>
            </div>
            <br clear="all" />
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="region" class="form-label required">Région </label
                ><br />
                <select
                  class="form-select"
                  id="region"
                  name="region"
                  aria-label="Default select example"
                  v-model="add_data.regions_id"
                >
                  <option
                    v-for="region in regions"
                    :key="region.id"
                    :value="region.id"
                  >
                    {{ region.label }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="region" class="form-label required">Genre </label
                ><br />
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="Homme"
                    name="Homme"
                    value="Homme"
                    v-model="add_data.sexe"
                  />
                  <label class="form-check-label normal_weight" for="Homme"
                    >&nbsp; Homme</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="Femme"
                    name="Femme"
                    value="Femme"
                    v-model="add_data.sexe"
                  />
                  <label class="form-check-label normal_weight" for="Femme"
                    >&nbsp; Femme</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="Nom-Binaire"
                    name="Nom-Binaire"
                    value="Nom-Binaire"
                    v-model="add_data.sexe"
                  />
                  <label
                    class="form-check-label normal_weight"
                    for="Nom-Binaire"
                    >&nbsp; Nom Binaire</label
                  >
                </div>
              </div>
            </div>
            <div></div>
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <input
                  class="form-check-input"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                  v-model="add_data.newsletter"
                />
                <label class="form-check-label normal_weight" for="newsletter"
                  >&nbsp; &nbsp; Newletter
                </label>
              </div>
              <div class="col-md-6"></div>
            </div>
            <br />
            <div></div>
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label class="form-label normal_weight required">
                  Date De Naissance
                </label>
              </div>
              <div class="col-md-6"></div>
            </div>
            <br />
            <div class="row">
              <div class="row">
                <div class="col-sm-4">
                  <label for="mois" class="form-label normal_weight"
                    >Mois:</label
                  >
                  <input
                    class="form-control"
                    list="id_mois"
                    name="mois"
                    id="mois"
                    v-model="add_data.month"
                  />
                  <datalist id="id_mois">
                    <option value="01">Janvier</option>
                    <option value="02">Février</option>
                    <option value="03">Mars</option>
                    <option value="04">Avril</option>
                    <option value="05">Mai</option>
                    <option value="06">Juin</option>
                    <option value="07">Juillet</option>
                    <option value="08">Août</option>
                    <option value="09">Septembre</option>
                    <option value="10">Octobre</option>
                    <option value="11">Novembre</option>
                    <option value="12">Décembre</option>
                  </datalist>
                </div>
                <div class="col-sm-4">
                  <label for="jours" class="form-label normal_weight"
                    >Jour:</label
                  >
                  <input
                    class="form-control"
                    list="id_jours"
                    name="jours"
                    id="jours"
                    v-model="add_data.day"
                  />
                  <datalist id="id_jours">
                    <option value="01"></option>
                    <option value="02"></option>
                    <option value="03"></option>
                    <option value="04"></option>
                    <option value="05"></option>
                    <option value="06"></option>
                    <option value="07"></option>
                    <option value="08"></option>
                    <option value="09"></option>
                    <option value="10"></option>
                    <option value="11"></option>
                    <option value="12"></option>
                    <option value="13"></option>
                    <option value="14"></option>
                    <option value="15"></option>
                    <option value="16"></option>
                    <option value="17"></option>
                    <option value="18"></option>
                    <option value="19"></option>
                    <option value="20"></option>
                    <option value="21"></option>
                    <option value="22"></option>
                    <option value="23"></option>
                    <option value="24"></option>
                    <option value="25"></option>
                    <option value="26"></option>
                    <option value="27"></option>
                    <option value="28"></option>
                    <option value="29"></option>
                    <option value="30"></option>
                    <option value="31"></option>
                  </datalist>
                </div>
                <div class="col-sm-4">
                  <label for="years" class="form-label normal_weight"
                    >Année:</label
                  >
                  <input
                    class="form-control"
                    list="browsers"
                    name="years"
                    id="years"
                    v-model="add_data.years"
                  />
                  <datalist id="browsers">
                    <option
                      v-for="year in tabYears"
                      :key="year.idex"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </datalist>
                </div>
              </div>
            </div>
            <br /><br />
            <br />
          </form>
        </div>
        <!--End Body modal add user-->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { defineComponent, onMounted, ref } from "vue";
// import { config } from "@/core/helpers/config";
// import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
// import { themeName } from "@/core/helpers/documentation";
// import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ApiService } from "@/core/services/ApiService";

import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import loader from "./utility/loader.vue";
import Toggle from "@vueform/toggle";

export default {
  components: {
    loader,Toggle,
  },
  data() {
    return {
      data: {},
      add_data: {
        typeusers_id: 1,
      },
      regions: {},
      formMode: "ajouter",
      currentYear: new Date().getFullYear(),
      tabYears: [],
      showloader: false,

      totalpage: 0,
      currentpage: 1,
      limit: 10,
      sum: 0,
      offset: 0,
      searchValue: "",
      orderStatus: "asc",
      url:''
    };
  },
  methods: {
    // getlisteYear() {
    //   const start = 1920;
    //   const end = this.currentYear;
    //   let list = Array(end - start + 1)
    //     .fill()
    //     .map((_, idx) => start + idx);
    //   this.tabYears = list.reverse();
    // },
    IsActiveUser(item) {
      ApiService.setHeader();
      ApiService.post("/user/isActive", item)
        .then((resp) => {
          console.log("isActive ok!");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListeUser() {
      this.showloader = true;
      this.offset = this.limit * (this.currentpage - 1);
      ApiService.setHeader();
      ApiService.query("membre/All", {
        params: {
          searchValue: this.searchValue,
          limit: this.limit,
          offset: this.offset,
        },
      })
        .then((result) => {
          if (result.data) {
            this.data = result.data.liste;
            console.log(this.data);
            this.sum =  result.data.sum;
            this.initpage();
            this.showloader = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.showloader = false;
        });
    },
    clickCallback(pageNum) {
      this.currentpage = pageNum;
      this.getListeUser();
    },
    async initpage() {
      this.totalpage = await Math.ceil(this.sum / this.limit);
    },
    async searchdata() {
      this.currentpage = await 1;
      this.getListeUser();
    },
    // getRegion() {
    //   ApiService.setHeader();
    //   ApiService.get("region")
    //     .then((result) => {
    //       this.regions = result.data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    submit() {
      if (this.formMode == "ajouter") {
        ApiService.setHeader();
        ApiService.post("auth/register", this.add_data)
          .then((response) => {
            this.alertSucess();
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        ApiService.setHeader();
        ApiService.post("user/update", this.add_data)
          .then((response) => {
            this.alertSucess();
          })
          .catch((error) => {
            console.log("Error", error.message);
            console.log("error request", error.request.response);
            this.errors = error.request.response;
          });
      }
    },
    resetData() {
      this.add_data = {};
    },
    maj(item, val) {
      this.formMode = val;
      if (val == "editer") {
        const data_tmp = JSON.parse(JSON.stringify(item));
        console.log(data_tmp);
        this.add_data = data_tmp;
      }
    },

    alertSucess() {
      Swal.fire({
        text: "Enregistrer avec succes!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        location.reload();
      });
    },
    async getExports() {
      this.url = await process.env.VUE_APP_API_URL;
    }
  },
  mounted() {
    this.getListeUser();
    this.getExports();
    //this.getRegion();
    //this.getlisteYear();
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>
<style>
.toggle-blue {
  --toggle-bg-on: #009ef7 !important;
  --toggle-border-on: #009ef7 !important;
}
</style>
